<template>
  <div class="paying">
    <div>
      <span class="pay_titile">报名信息</span>
      <div class="pay_top">
        <div>营地名称：{{ $store.state.info.name }}</div>
        <div>真实姓名：{{ personalInfo.name }}</div>
        <div>身份证号：{{ personalInfo.id_card }}</div>
        <!-- <div>缴费时间：{{ personalInfo.create_time }}</div> -->
        <div>报名费用： {{ has_amount }}元</div>
      </div>
      <div class="line"></div>
      <div class="pay-money flex">
        <div>
          报名费用 ：<span>￥{{ has_amount }} </span>
        </div>
        <div class="btn">
          <el-button @click="paying()" v-if="$store.state.human == 1"
            >上传缴费截图
          </el-button>
          <el-button @click="paying()" v-else>立即支付</el-button>
        </div>
      </div>

      <div>
        <!-- 清华大学人文学院 “中国现当代文学经典研读”学分课程 -->
        <div
          class="pay_bottom"
          v-if="
            $store.state.human == 1 &&
            $store.state.indexIdentity == '6c6884d1c83040ee8ca17736a8d67b93'
          "
        >
          <div class="cc"><span class="starIcon">*</span>注意事项：</div>
          <div>
            请参照清华大学人文学院“中国现当代文学经典研读”学分课程简章中缴费方式。
            <span class="cc">汇款或扫码支付后请务必保留付款截图</span>。
          </div>
          <div>
            1、银行转账汇款时，请在附言/用途栏中注明 "21069363022 +学员姓名"。
          </div>
          <div>2、扫码支付时，请在备注栏中注明“学员姓名”。</div>
          <div>3、未满18岁的学员，缴费请由家长支付。</div>
        </div>
        <!-- 清华历史课“中国历史通解”（第二期）学分课程  -->
        <div
          class="pay_bottom"
          v-else-if="
            $store.state.human == 1 &&
            $store.state.indexIdentity == '479205eb2dbf48fcbbeaee710a9b9d24'
          "
        >
          <div class="cc"><span class="starIcon">*</span>注意事项：</div>
          <div>1、支付成功后请务必保留付款截图。</div>
          <div>
            2、银行转账汇款时，请在附言/用途栏中注明“22069363005+学员姓名”。
          </div>
          <div>3、未满18岁的学员，缴费请由家长支付。</div>
          <div class="cc" style="margin-top: 10px">
            <span class="starIcon">*</span>汇款信息：
          </div>
          <div>收款单位：清华大学</div>
          <div>帐号：0200004509089131550</div>
          <div>开户行：工行北京分行海淀西区支行</div>
          <div>附言/用途：请在附言栏中注明“22069363005+学员姓名”</div>
        </div>
        <div class="pay_bottom" v-else>
          <div class="cc"><span class="starIcon">*</span>注意事项：</div>
          <div>
            1、学生支付完成后请不要立即关闭浏览器，等待支付完成返回本页面，以确认支付完成。
          </div>
          <div>
            2、支付确认后，将不能再修改姓名和身份证号，请在支付前核对信息。
          </div>
          <div>
            3、如果在完成支付后，状态许久未更新为“报名成功”，请仔细核查自己支付账号是否已扣费。
          </div>
          <div>
            4、对于多次缴费的学生，除生效一笔之外其他重复支付的费用会在缴费结束阶段统一退费。
          </div>
          <!-- <div>5、网上缴费如有问题，请致电项目组工作人员：XXX-XXXXXXXXX。</div> -->
        </div>
      </div>
    </div>
    <!-- 支付扫码框 -->
    <!-- <el-dialog title="微信支付" :visible.sync="dialogVisible" width="20%">
      <div class="pay_code">
        <div class="pay_time">支付剩余时间<span>15分30秒</span></div>
        <div>￥150</div>
        <div class="pay_img">
         <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
        <div>请使用<span>微信</span>扫一扫，扫描二维码支付</div>
        <div>手机完成支付后，请等待系统处理，在此之前请勿关闭窗口</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn">
          <el-button @click="dialogVisible = false">刷新二维码</el-button>
        </div>
      </span>
    </el-dialog> -->
    <!-- 提示：打开电脑端后不能切换手机端 -->
    <el-dialog
      :before-close="handleClose"
      :visible.sync="dialogPayingVisible"
      title="提示"
      width="30%"
    >
      <div>
        <div v-if="this.transfer_account == 1">
          <div>
            <div>为确保缴费流程顺利，请注意：</div>
            <div>1、请在缴费时仔细核对收款账户信息，确保信息准确无误。</div>
            <div>
              2、完成支付后，将带有订单编号的付款截图作为转账凭证上传至指定界面。
            </div>
          </div>
        </div>
        <div v-else>
          <div class="margin1">
            如您使用手机报名：点击“确认”后将直接发起微信支付，按照指引完成支付即可。
          </div>
          <div class="margin1">
            如您使用电脑报名：点击“确认”后系统将出现支付二维码，使用手机微信或支付宝扫码，按照指引完成支付即可。
          </div>
          <div>
            <div>为保障支付顺利，请注意：</div>
            <div>1、请确保支付账户余额充足。</div>
            <div>
              2、如因支付失败需再次支付时，务必使用和第一次支付时相同的微信或支付宝账号进行操作。
            </div>
          </div>
        </div>
      </div>

      <span class="dialog-footer" slot="footer">
        <el-button @click="dialogPayingVisible = false">取 消</el-button>
        <el-button @click="toPaying" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 微信支付宝2选1 -->
    <el-dialog
      :before-close="handleClose"
      :visible.sync="dialogChoose"
      title="支付方式"
      width="600px"
    >
      <div class="choose">
        <div class="flex img_choose">
          <img
            @click="toWechat"
            alt=""
            src="../../../assets/img/paying/weichat.png"
          />
          <img
            @click="toPaybao"
            alt=""
            src="../../../assets/img/paying/paybao.png"
          />
        </div>
        <div class="text_choose">请选择支付方式</div>
      </div>
    </el-dialog>
    <!-- 微信支付 -->
    <confirm-sign-up
      :confirmSignUpDialogFu="confirmSignUpDialogFu"
      @closeCFSUDialog="closeCFSUDialog"
    ></confirm-sign-up>
    <!-- 上传付款材料 -->
    <el-dialog
      :before-close="handleClose"
      :visible.sync="bankDialogVisible"
      title="银行卡支付"
      width="200"
      @open="openBank"
    >
      <div class="bank_pay">
        <div><span>收款单位:</span>{{ payee }}</div>
        <div><span>账号:</span>{{ bank_idcard }}</div>
        <div><span>开户行:</span>{{ bank_first }}</div>
        <div v-if="transfer_back_up">
          <span>转账请备注:</span>{{ transfer_back_up }}
        </div>
        <span>上传带有订单编号的转账截图</span>
        <div class="image_add">
          <div class="image_in">
            <el-upload
              :before-upload="beforeAvatarUpload"
              :class="{ hide: hideUpload }"
              :file-list="fileList"
              :http-request="uploadPicture"
              :limit="1"
              :on-progress="handleUploadProgress"
              :on-success="uploadSuccess"
              accept=".jpg,.jpeg,.png"
              action="#"
              list-type="picture-card"
              name="multipartFile"
              ref="imgUrl"
            >
              <div slot="file" slot-scope="{ file }">
                <div :key="idx" v-for="(item, idx) in fileList">
                  <img
                    :src="file.url"
                    alt=""
                    class="el-upload-list__item-thumbnail"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      @click="handlePictureCardPreview(file)"
                      class="el-upload-list__item-preview"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      @click="handleDownload(file)"
                      class="el-upload-list__item-delete"
                      v-if="!disabled"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                    <span
                      @click="handleRemove(file)"
                      class="el-upload-list__item-delete"
                      v-if="!disabled"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </div>
              <i class="el-icon-circle-plus"> </i>
            </el-upload>
          </div>

          <el-dialog :visible.sync="dialogVisible">
            <img :src="resultPicture" alt="" width="100%" />
          </el-dialog>
        </div>
      </div>

      <span class="dialog-footer" slot="footer">
        <el-button @click="bankDialogVisible = false">取 消</el-button>
        <el-button @click="uploadConfirm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import ConfirmSignUp from "c/index/SignUp/ConfirmSignUp";
import { delPayingPic, getAccount, signUpInit } from "r/index/signUp";
import { getWxConfig } from "r/index/pay";
import { achievementCallBack, cosSignature } from "r/index/cosUpload";
import COS from "cos-js-sdk-v5";

export default {
  name: "Paying",
  components: {
    ConfirmSignUp,
  },
  data() {
    return {
      dialogPayingVisible: false, //支付时候tip
      dialogChoose: false, //付款方式选择
      confirmSignUpDialogFu: false, // 控制确认报名弹框的显示与隐藏
      bankDialogVisible: false, //上传银行截图弹框的显示与隐藏
      personalInfo: {},
      camp_name: "", //营地名称
      has_amount: "", //支付金额
      status: "", //状态
      status_wx: "", //状态
      type: "", //状态
      //支付状态判断
      transfer_account: window.localStorage.getItem(
        "transfer_account" + this.$store.state.indexIdentity
      ),
      payee: window.localStorage.getItem(
        "payee" + this.$store.state.indexIdentity
      ),
      bank_first: window.localStorage.getItem(
        "bank_first" + this.$store.state.indexIdentity
      ),
      bank_idcard: window.localStorage.getItem(
        "bank_idcard" + this.$store.state.indexIdentity
      ),
      transfer_back_up: window.localStorage.getItem(
        "transfer_back_up" + this.$store.state.indexIdentity
      ),
      isUploading: false, // 图片上传蒙层
      dialogVisible: false,
      fileList: [],
      file: "",
      file_image: {},
      resultPicture: "",
      disabled: false, //限制输入框
      hideUpload: false,
      isBank_infoFile: false,
    };
  },
  created() {
    this.getAccount();
    this.commonApi(33);
    const code = this.$store.state.indexIdentity;
    this.has_amount = window.localStorage.getItem("has_amount" + code);
    // 已上传的银行付款图片
    let bank_infoFile = window.localStorage.getItem(
      "sign_up_payfile_" + window.localStorage.getItem("index-phone-all") + code
    );
    if (bank_infoFile && JSON.parse(bank_infoFile).length > 0) {
      JSON.parse(bank_infoFile).map((i) => {
        if (i.url != "") {
          this.isBank_infoFile = true;
        }
      });
    }
    if (
      bank_infoFile &&
      this.isBank_infoFile == true &&
      JSON.parse(bank_infoFile).length > 0
    ) {
      // console.log(JSON.parse(bank_infoFile));
      this.fileList = JSON.parse(bank_infoFile);
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 子组件触发，关闭确认报名弹框
    closeCFSUDialog(val, isSuccess) {
      console.log(val, "val");
      console.log(isSuccess, "isSuccess");
      this.confirmSignUpDialogFu = val;
      if (isSuccess == 1) {
        this.$emit("getstatus", 6);
      }
    },
    toWechat() {
      // this.dialogChoose = false;
      this.confirmSignUpDialogFu = true;
    },
    toPaybao() {
      getWxConfig({
        pay_type: 2,
      }).then((res) => {
        console.log(res, "getWxConfig");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        /* 此处form就是后台返回接收到的数据 */
        let form = res.data.data;
        console.log(form);
        // return
        let div = document.createElement("div");
        div.innerHTML = form;
        document.body.appendChild(div);
        document.forms[0].acceptCharset = "GBK"; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地.
        // 重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
        document.forms[0].submit(); //
        let alipay_submit = document.getElementById("alipay_submit");
        div.removeChild(alipay_submit);
      });
    },
    // 立即支付-按钮
    paying() {
      let code = this.$store.state.indexIdentity;
      if (code == "3c7e84455750447799e4b70bda5f4493") {
        this.toPaying();
      } else {
        this.dialogPayingVisible = true;
      }
    },
    toPaying() {
      this.commonApi(34);
      this.dialogPayingVisible = false;
      if (this.has_amount == 0) {
        getWxConfig({ pay_type: 3 }).then((res) => {
          console.log(res, "signUpInit");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.err_msg);
          this.$emit("getstatus", 6);
        });
      } else {
        // transfer_account 判断微信支付宝或者银行卡支付
        if (this.transfer_account == 0) {
          this.dialogChoose = true;
        } else {
          this.bankDialogVisible = true;
          if (this.fileList.length >= 1) {
            this.hideUpload = true;
          }
        }
      }
      // setTimeout(this.signUpInit(this.status_wx), 1000);
    },
    signUpInit() {
      signUpInit({}).then((res) => {
        console.log(res, "signUpInit");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.status = res.data.status;
        // return;
        // 点击下一步传值给status,更新
        if (this.status == 4) {
          this.type = "check";
          this.status = 3;
          if (this.isNexts == 1) {
            if (res.data.examine_status == 1) {
              this.type = "pay";
              this.status = 4;
            } else if (res.data.examine_status == 2) {
              this.type = "referInfo";
              this.status = 2;
            }
          }
          if (res.data.pay_amount == 0) {
            getWxConfig({ pay_type: 3 }).then((res) => {
              console.log(res, "signUpInit");
              if (res.data.code != 200) {
                return this.$message.error(res.data.msg);
              }
              this.$message.success(res.data.err_msg);
            });
          }
          // pay_amount支付金额等于0，直接跳到成功页面
        } else if (this.status == 5) {
          this.type = "pay";
          // pay_amount支付金额等于0，直接跳到成功页面
          if (res.data.pay_amount == 0) {
            this.type = "success";
            this.$emit("getstatus", 6);
          } else {
            // transfer_account 判断微信支付宝或者银行卡支付
            if (this.transfer_account == 0) {
              this.dialogChoose = true;
            } else {
              this.bankDialogVisible = true;
              if (this.fileList.length >= 1) {
                this.hideUpload = true;
              }
            }
          }
        }
        //  else if (this.status == 6) {
        //   this.type = "success";
        // }
        this.$emit("getstatus", res.data.status);
      });
    },
    getAccount() {
      getAccount({}).then((res) => {
        // console.log(res, "getAccount");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.personalInfo = res.data.info;
        // this.personalInfo.create_time = parseTime(res.data.info.create_time);
      });
    },
    openBank() {},
    // 上传银行卡转账截图

    // 图片上传成功前钩子
    beforeAvatarUpload(file) {
      // console.log(file, "beforeImage");
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJPEG = file.type === "image/jpeg";
      const isLt10M = file.size / 1024 / 1024 < 10;
      //  console.log(isLt10M)
      if (!isJPG && !isPng && !isJPEG) {
        this.$message.info("上传图片只能是 JPG,JPEG或png 格式!");
      }
      if (!isLt10M) {
        this.$message.info("上传图片大小不能超过 10MB!");
      }
      return (isJPG || isPng || isJPEG) && isLt10M;
    },
    handlePictureCardPreview(file) {
      console.log(file, "11111111");
      this.resultPicture = file.url;
      this.dialogVisible = true;
    },
    // 文件上传中处理
    handleUploadProgress(event, file, fileList) {
      // console.log(fileList);
      this.isUploading = true;
      if (this.fileList.length < 1) {
        return this.$message.info("超过上传数量");
      }
    },
    uploadSuccess(res, file, fileList) {
      this.referForm.achievementPath = res;
      this.fileList = {
        name: file.name,
        url: res,
      };

      this.$refs["imgUrl"].clearFiles(); //上传成功之后清除历史记录
    },
    uploadPicture(params) {
      // console.log("已经准备好上传图片", params);
      this.file = params.file;
      let raw = params.file;
      let image = {
        name: raw.name,
        size: raw.size,
        type: raw.type,
      };
      this.file_image = image;
      this.getCosConfigs(this.file_image, params.file);
    },
    // 上传截图
    uploadConfirm() {
      this.bankDialogVisible = false;
      // this.$message.success("你的支付信息已提交，请留意短信通知。");
      // this.getOssConfigs(this.file_image, this.file);
    },
    async changeStatus() {
      // console.log(SERVER_WS_URL);
      let { data: res } = await getWxConfig({
        pay_type: 4,
      });
    },
    // cos上传
    async getCosConfigs(image, file) {
      this.loading = true;
      await cosSignature({ image: image, index: "3" })
        .then((res) => {
          let resData = res.data.data;
          if (res.data.code === 200) {
            const cos = new COS({
              // 必选参数
              getAuthorization: (options, callback) => {
                callback({
                  TmpSecretId:
                    resData.credentials.credential.credentials.tmpSecretId,
                  TmpSecretKey:
                    resData.credentials.credential.credentials.tmpSecretKey,
                  XCosSecurityToken:
                    resData.credentials.credential.credentials.sessionToken,
                  StartTime: resData.credentials.credential.startTime, // 时间戳，单位秒，如：1580000000
                  ExpiredTime: resData.credentials.credential.expiredTime, // 时间戳，单位秒，如：1580000900
                });
              },
            });
            console.log(resData);
            cos.putObject(
              {
                Bucket: resData.credentials.bucket,
                Region: resData.credentials.region,
                Key: resData.key,
                Body: file,
                onProgress: (progressData) => {
                  console.log("上传进度：" + JSON.stringify(progressData));
                },
              },
              (err, data) => {
                console.log(data);
                if (err) {
                  console.log(err);
                  this.loading = false;
                  this.$message.error("上传失败！");
                } else {
                  console.log(data);
                  this.signUploadSuccess("http://" + data.Location, resData);
                }
              }
            );
          } else {
            this.$message.error(resData.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // cos上传-告诉后端上传成功
    async signUploadSuccess(path, resData) {
      await achievementCallBack({ key: resData.key, index: 3 })
        .then((res) => {
          if (res.data.code != 200) {
            this.loading = false;
            return this.$message.error("上传失败！");
          }
          console.log(res);
          this.loading = false;
          this.$message.success("上传成功！");
          this.resultPicture = path + "?t=" + Date.parse(new Date());
          this.$forceUpdate();
          let obj = {
            url: res.data.picture_path,
          };
          this.fileList.push(obj);
          let code = this.$store.state.indexIdentity;
          window.localStorage.setItem(
            "sign_up_payfile_" +
              window.localStorage.getItem("index-phone-all") +
              code,
            JSON.stringify(this.fileList)
          );
          this.$message.success({
            message: "您已上传成功，等待短信通知，审核通过后登录报名网址。",
            duration: 5000,
          });
          this.hideUpload = this.fileList.length >= 1;
          this.changeStatus();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // async getOssConfigs(image, file) {
    //   let obj = {
    //     url: "",
    //     savefile: "",
    //   };
    //   await getBankInfoOss({image: image})
    //     .then((res) => {
    //       console.log(res, "开始上传图片");
    //       if (res.data.code === 200) {
    //         let oss_url = res.data.config.host;
    //         let filename = res.data.config.file;
    //         let formData = new FormData();
    //         formData.append("OSSAccessKeyId", res.data.config.accessid);
    //         formData.append("policy", res.data.config.policy);
    //         formData.append("signature", res.data.config.signature);
    //         formData.append("filename", res.data.config.file);
    //         formData.append("key", res.data.config.savefile);
    //         formData.append("callback", res.data.config.callback);
    //         formData.append("success_action_status", 200);
    //         formData.append("file", file);
    //         // console.log(formData);
    //         upload(oss_url, formData)
    //           .then((ress) => {
    //             console.log(555555555);
    //             console.log(ress, "ress");
    //             if (ress.data.code != 200) {
    //               return this.$message.error(ress.data.msg);
    //             }
    //             this.resultPicture = filename + "?t=" + Date.parse(new Date());
    //             // this.$forceUpdate();
    //             obj = {
    //               url: ress.data.picture_path,
    //               savefile: res.data.config.savefile,
    //             };
    //             this.fileList.push(obj);
    //             let code = this.$store.state.indexIdentity;
    //             window.localStorage.setItem("sign_up_payfile_" + window.localStorage.getItem("index-phone-all") + code, JSON.stringify(this.fileList));
    //             this.$message.success({
    //               message:
    //                 "您已上传成功，等待短信通知，审核通过后登录报名网址。",
    //               duration: 5000,
    //             });
    //             this.changeStatus();
    //             this.hideUpload = this.fileList.length >= this.limitCount;
    //           })
    //           .catch((err) => {
    //             this.$message.error(err);
    //           });
    //       } else {
    //         this.$message.error(res.data.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       // console.log(err);
    //     });
    // },
    // 下载证明资料图片
    handleDownload(file) {
      // console.log(file,'file22');
      window.location.href = file.url;
    },
    //删除图片
    handleRemove(file) {
      console.log(file, "file");
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let getfile = file.url;
          console.log(file);
          // console.log(savefile.match(/^(?:[^\/]|\/\/)*/),'222222')；//获取域名
          let str = getfile.split(
            "https://yingdi-bank-info-1320656968.cos.ap-beijing.myqcloud.com/"
          ); //https://yingdi-bank-info-1320656968.cos.ap-beijing.myqcloud.com/进行分割，

          let savefile = str[1].split("?")[0];
          delPayingPic({ object: savefile }).then((res) => {
            if (res.data.code != 200) {
              return this.$message.error(res.data.msg);
            }
            this.fileList.forEach((item, idx) => {
              if (item.url == file.url) {
                this.fileList.splice(idx, 1);
                let code = this.$store.state.indexIdentity;
                window.localStorage.setItem(
                  "sign_up_payfile_" +
                    window.localStorage.getItem("index-phone-all") +
                    code,
                  JSON.stringify(this.fileList)
                );
                this.$message.success("删除成功");
                this.hideUpload = this.fileList.length >= 1;
                window.localStorage.removeItem(
                  "sign_up_payfile_" +
                    window.localStorage.getItem("index-phone-all") +
                    code
                );
              }
            });
          });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "a/scss/btn";
@import "a/scss/dialog";

.btn {
  padding-bottom: 0 !important;
}

.flex {
  display: flex;
}

.margin1 {
  margin-bottom: 1rem;
}

::v-deep .hide {
  .el-upload--picture-card {
    display: none;
  }

  .el-upload-list__item div {
    height: 100%;
  }
}

.paying {
  padding: 0 56px 65px 56px;

  .pay_titile {
    font-size: 18px;
    font-weight: 500;
    color: #12141c;
    line-height: 18px;
  }

  .pay_top {
    margin: 24px 0;

    div {
      font-size: 14px;
      font-weight: 500;
      color: #12141c;
      line-height: 32px;
    }
  }

  .line {
    height: 1px;
    border-bottom: 1px solid #d1d1d1;
  }

  .pay-money {
    font-size: 18px;
    font-weight: 500;
    color: #12141c;
    line-height: 24px;
    justify-content: space-between;
    margin: 43px 0 0 0;

    span {
      color: #60194a;
    }
  }

  .pay_bottom {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    line-height: 21px;

    .cc {
      font-weight: 600;
      color: black;
    }

    .starIcon {
      color: red;
    }
  }
}

.choose {
  text-align: center;

  .img_choose {
    justify-content: space-around;

    img {
      width: 38%;
      cursor: pointer;
    }
  }

  .text_choose {
    font-size: 15px;
    margin: 20px 0 50px 0;
  }

  .btn .el-button {
    width: 120px !important;
  }
}

// .pay_code {
//   text-align: center;
//   :nth-child(1) {
//     font-size: 14px;
//     font-weight: 500;
//     color: #12141c;
//     line-height: 14px;
//     span {
//       font-size: 13px !important;
//       color: var(--color);
//     }
//   }
//   :nth-child(2) {
//     font-size: 14px;
//     font-weight: 500;
//     color: var(--color);
//     line-height: 24px;
//   }
//   :nth-child(4) {
//     font-size: 14px;
//     font-weight: 500;
//     color: #333333;
//     line-height: 16px;
//     span {
//       font-size: 17px !important;
//       color: var(--color);
//     }
//   }
//   :nth-child(5) {
//     font-size: 12px;
//     font-weight: 500;
//     color: #666666;
//     line-height: 12px;
//   }
// }

.bank_pay {
  & div {
    margin: 8px 0;
  }

  & span {
    font-weight: 600;
  }
}
</style>
